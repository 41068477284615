.speaker-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px;
  font-weight: 500;
}
.row {
  display: flex;
  justify-content: center;
}
.form-title {
  font-size: 1rem;
  font-weight: 600;
}
.form-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 300px;
  margin: 20px 10px;
}
.form-row label {
  height: 20px;
}
.form-row input[type="text"],
.form-row input[type="email"],
.form-row input[type="tel"] {
  background-color: #ffffff;
  border: 0;
  border-bottom: 1px solid black;
  width: 100%;
  min-width: 200px;
  padding: 7px;
  font-size: 14px;
}
.form-row input[name="line"] {
  border-bottom: 2px solid #fff204;
}
.form-row textarea {
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid black;
  width: 100%;
  height: 36px;
  min-height: 36px;
  min-width: 200px;
  resize: vertical;
}
.form-row button {
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  color: black;
  background-color: #fff204;
  border: none;
  padding: 10px 5px;
  cursor: pointer;
}
.form-row button:hover {
  background-color: rgb(252, 250, 126);
}
.form-row button:active {
  background-color: rgb(230, 226, 0);
}
.swal-text {
  text-align: center;
}
.swal-button {
  color: black;
  background-color: #fff204;
}
.swal-button:not([disabled]):hover {
  background-color: rgb(252, 250, 126);
}

@media screen and (min-width: 501px) and (max-width: 766px) {
  .speaker-form {
    padding: 10px 20px;
  }
  .form-title {
    text-align: center;
  }
  .form-row {
    min-width: 450px;
  }
  .form-row button {
    margin: 0 auto;
  }
}

@media screen and (min-width: 301px) and (max-width: 500px) {
  .speaker-form {
    padding: 10px 20px;
  }
  .form-row {
    min-width: 290px;
  }
  .form-title {
    text-align: center;
  }
  .form-row button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 300px) {
  .speaker-form {
    padding: 10px 20px;
  }
  .form-row {
    min-width: 260px;
  }
  .form-title {
    text-align: center;
  }
  .form-row button {
    margin: 0 auto;
  }
}
