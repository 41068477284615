#clients {
  background-color: lightgray;
}
.clients-title {
  font-size: 3rem;
  padding: 4rem;
  padding-top: 6rem;
  text-align: center;
}
.clients-line {
  display: block;
  margin: 0 auto;
  margin-bottom: 5rem;
}
.clients-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 1rem 19rem;
  padding-bottom: 10rem;
}
.client-container {
  margin: 1.5rem;
}

.client-container img {
  max-width: 200px;
}

@media screen and (min-width: 1209px) and (max-width: 1650px) {
  .clients-list-container {
    padding: 1rem 8rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1208px) {
  .clients-list-container {
    padding: 1rem 4rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 501px) and (max-width: 766px) {
  .clients-list-container {
    padding: 1rem 2rem;
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 321px) and (max-width: 500px) {
  .clients-title {
    font-size: 1.8rem;
    padding: 3rem 1.5rem 1rem 1.5rem;
  }
  .clients-line {
    margin: 2rem auto;
  }
  .clients-list-container {
    width: 250px;
    padding: 0;
    padding-bottom: 2rem;
  }
  .client-container img {
    max-width: 180px;
  }
}

@media screen and (max-width: 320px) {
  .clients-title {
    font-size: 1.4rem;
    padding: 2rem 1rem 0.5rem 1rem;
  }
  .clients-line {
    margin: 2rem auto;
  }
  .clients-list-container {
    width: 250px;
    padding: 0;
    padding-bottom: 2rem;
  }
  .client-container img {
    max-width: 180px;
  }
}
