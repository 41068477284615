#banner {
  background-color: #fff204;
  margin-top: 0;
  width: 100%;
  /* height: auto; */
  overflow: hidden;
}
.banner-track {
  display: flex;
  animation: scroll 20s linear infinite;
  width: calc(130px * 28);
}
.slide {
  height: 145px;
  width: auto;
}
.slide img {
  width: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-130px * 14));
  }
}
