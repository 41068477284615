.container-navbar {
  border-bottom: 1px solid white;
}
.navbar-brand:hover {
  opacity: 0.8;
}
.navbar {
  flex-wrap: nowrap;
  justify-content: space-evenly;
}
.navbar-nav {
  flex-direction: row;
  margin: 0 auto;
  margin-left: 12vw;
}
.nav-link {
  font-size: 1.13rem;
  letter-spacing: 4px;
  margin: 0 0.8rem;
  color: white;
}
.nav-link.active {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-link.active img {
  width: 50px;
  position: relative;
  top: 30px;
  left: 11px;
}
.social {
  margin: 0 0.5rem;
}
.social img:hover {
  opacity: 0.8;
}

@media screen and (min-width: 2284px) {
  .container {
    max-width: 1800px;
  }  
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .container {
    max-width: 1060px;
    padding: 0 2rem;
  }
  .navbar-nav {
    margin-left: auto;
  }
  .nav-link {
    font-size: 1rem;
    margin: 0 0.4rem;
    letter-spacing: 3px;
  }
  .nav-link.active img {
    display: none;
  }
  .social {
    margin: 0 0.2rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .container {
    max-width: 800px;
    padding: 0 2rem;
  }
  .navbar-toggler {
    display: none;
  }
  .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
  }
  .navbar-nav {
    margin-left: auto;
  }
  .nav-link {
    font-size: 1rem;
    margin: 0 0.3rem;
    letter-spacing: 1.8px;
  }
  .nav-link.active img {
    display: none;
  }
  .social {
    margin: 0 0.2rem;
  }
  .social img {
    width: 28px;
  }
}

@media screen and (min-width: 500px) and (max-width: 766px) {
  .navbar {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-link {
    color: white;
  }
  .nav-link.active {
    font-weight: 700;
  }
  .nav-link.active img {
    display: none;
  }
  .social {
    margin: 0 0.2rem;
  }
}

@media screen and (max-width: 499px) {
  .navbar {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-link {
    color: white;
  }
  .nav-link.active {
    font-weight: 700;
  }
  .nav-link.active img {
    display: none;
  }
  .social {
    margin: 0 0.2rem;
  }
}
