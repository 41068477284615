.background-parallax {
  background-image: url(../../../public/images/pictures/foto_portada.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-position-x: right;
  background-attachment: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  inset: 0;
  z-index: -2;
}

@media screen and (min-width: 501px) and (max-width: 1025px) {
  .background-parallax {
    background-image: url(../../../public/images/pictures/foto_portadaTablet.jpg);
    background-attachment: scroll;
  }
  
}

@media screen and (max-width: 500px) {
  .background-parallax {
    background-image: url(../../../public/images/pictures/foto_portadaMobile.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 50%;
    background-attachment: scroll;
    overflow: hidden;
    top: 0;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    margin-bottom: 0;
    inset: 0;
    z-index: -2;
  }
}
