.map-shipick {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 766px) {
  .map-shipick {
    height: 60vh;
  }
}
