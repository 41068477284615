.parallax-content-mobile {
  display: none;
}
.parallax-content {
  position: relative;
  top: 12vw;
  left: 12vw;
  color: white;
  letter-spacing: 1.5px;
  width: 70vw;
  min-height: 720px;
}
.parallax-content h2 {
  font-size: 5rem;
  font-weight: 200;
}
.parallax-content h2 b {
  font-weight: 600;
}
.parallax-content img {
  margin: 50px 0;
}
.parallax-content p {
  font-size: 2rem;
}

@media screen and (min-width: 1400px) {
  .parallax-content {
    top: 9vw;
  }
}

@media screen and (min-width: 1232px) and (max-width: 1399px) {
  .parallax-content {
    top: 11vw;
  }
}

@media screen and (min-width: 962px) and (max-width: 1231px) {
  .parallax-content {
    left: 5vw;
    width: 90vw;
  }
}

@media screen and (min-width: 767px) and (max-width: 961px) {
  .parallax-content {
    top: 12vw;
    left: 3vw;
    width: 90vw;
  }
  .parallax-content h2 {
    font-size: 3.8rem;
  }
  .parallax-content p {
    font-size: 1.8rem;
  }
  .parallax-content img {
    margin: 50px 0;
  }
}

@media screen and (min-width: 420px) and (max-width: 766px) {
  .parallax-content {
    top: 18vw;
    left: 3vw;
    width: 95%;
  }
  .parallax-content h2 {
    font-size: 1.8rem;
  }
  .parallax-content p {
    font-size: 1.2rem;
  }
  .parallax-content img {
    width: 2rem;
    margin: 50px 0;
  }
}

@media screen and (min-width: 381px) and (max-width: 419px) {
  .parallax-content {
    display: none;
  }
  .parallax-content-mobile {
    display: block;
    position: relative;
    color: white;
    letter-spacing: 1.5px;
    top: 30vw;
    left: 4.4vw;
    width: 85.5%;
  }
  .parallax-content-mobile h2 {
    font-size: 2.5rem;
    font-weight: 200;
  }
  .parallax-content-mobile h2 b {
    font-weight: 600;
  }
  .parallax-content-mobile p {
    font-size: 1.5rem;
  }
  .parallax-content-mobile img {
    width: 2rem;
    margin: 50px 0;
  }
}

@media screen and (min-width: 322px) and (max-width: 380px) {
  .parallax-content {
    display: none;
  }
  .parallax-content-mobile {
    display: block;
    position: relative;
    color: white;
    letter-spacing: 1.5px;
    top: 25vw;
    left: 4.6vw;
    width: 85.5%;
  }
  .parallax-content-mobile h2 {
    font-size: 2.3rem;
    font-weight: 200;
  }
  .parallax-content-mobile h2 b {
    font-weight: 600;
  }
  .parallax-content-mobile p {
    font-size: 1.3rem;
    padding-right: 0;
  }
  .parallax-content-mobile img {
    width: 2rem;
    margin: 50px 0;
  }
}

@media screen and (min-width: 301px) and (max-width: 321px) {
  .parallax-content {
    display: none;
  }
  .parallax-content-mobile {
    display: block;
    position: relative;
    color: white;
    letter-spacing: 1.5px;
    top: 15vw;
    left: 4.6vw;
    width: 85.5%;
  }
  .parallax-content-mobile h2 {
    font-size: 2rem;
    font-weight: 200;
  }
  .parallax-content-mobile h2 b {
    font-weight: 600;
  }
  .parallax-content-mobile p {
    font-size: 1rem;
  }
  .parallax-content-mobile img {
    width: 2rem;
    margin: 30px 0;
  }
}

@media screen and (max-width: 300px) {
  .parallax-content {
    display: none;
  }
  .parallax-content-mobile {
    display: block;
    position: relative;
    color: white;
    letter-spacing: 1.5px;
    top: 20vw;
    left: 4.6vw;
    width: 88%;
  }
  .parallax-content-mobile h2 {
    font-size: 1.8rem;
    font-weight: 200;
  }
  .parallax-content-mobile h2 b {
    font-weight: 600;
  }
  .parallax-content-mobile p {
    font-size: 1rem;
  }
  .parallax-content-mobile img {
    width: 2rem;
    margin: 30px 0;
  }
}
