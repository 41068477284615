#footer {
  background-color: black;
  color: white;
  height: 180px;
  display: flex;
  font-weight: 300;
  letter-spacing: 2px;
  list-style: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.links-footer-container {
  display: flex;
  justify-content: center;
}
.footer-item {
  color: white;
  text-decoration: none;
  margin: 2px 5px;
  padding: 3px 10px;
}
.footer-item:hover {
  color: white;
  opacity: 0.8;
}
.contacts-footer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 0.85rem;
}
.tel-email {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.tel-email-item {
  margin: 0 5px;
}
.tel-email h6 {
  margin-right: 30px;
}
.tel-email img {
  width: 20px;
  height: fit-content;
}
.tel-email a {
  text-decoration: none;
  color: white;
}
.follow-us {
  display: flex;
  margin-left: 30px;
  align-items: center;
}
.follow-us-item {
  margin: 0 5px;
}
.fui:hover {
  opacity: 0.8;
}
.tei:hover {
  opacity: 0.8;
}
.btn-home-footer {
  position: relative;
  left: 20rem;
  bottom: 1.5rem;
}
.btn-home-footer:hover {
  opacity: 0.8;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .btn-home-footer {
    left: 10rem;
  }
}

@media screen and (min-width: 981px) and (max-width: 1199px) {
  .btn-home-footer {
    left: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .btn-home-footer {
    display: none;
  }
  .contacts-footer {
    margin-top: 30px;
  }
}

@media screen and (min-width: 300px) and (max-width: 775px) {
  .links-footer-container {
    flex-wrap: wrap;
  }
  .contact-text {
    display: none;
  }
  .follow-us {
    margin: 0;
  }
  .follow-us h6 {
    display: none;
  }
}

@media screen and (max-width: 300px) {
  .links-footer-container {
    flex-wrap: wrap;
  }
  .contact-text {
    display: none;
  }
  .follow-us {
    margin: 0;
  }
  .follow-us h6 {
    display: none;
  }
  .tel-email {
    margin-right: 10px;
  }
  .tel-email h6 {
    margin-right: 5px;
  }
}
