#item-list-container {
  background-color: black;
}
.different {
  font-size: 3rem;
  padding: 4rem;
  padding-top: 6rem;
  padding-bottom: 2rem;
  text-align: center;
  color: white;
}
.item-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 5rem;
  align-items: baseline;
}
.item-list {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  color: white;
}
.container-list {
  margin: 4rem 5.5rem;
}
.container-list h5 {
  text-align: center;
}
.container-list p {
  display: block;
  max-width: 300px;
  text-align: center;
}

@media screen and (min-width: 767px) and (max-width: 1208px) {
  .container-list {
    margin: 2rem 3rem;
  }
}

@media screen and (min-width: 322px) and (max-width: 766px) {
  .different {
    font-size: 2rem;
    padding: 3rem;
    padding-bottom: 0.5rem;
  }
  .item-product {
    padding-bottom: 2rem;
  }
  .item-product h5 {
    font-size: 1.2rem;
  }
  .container-list {
    width: 260px;
    margin: 1.5rem 1.5rem;
    margin-bottom: 0;
  }
  .item-list {
    font-size: 1rem;
  }
  .item-product p {
    font-size: 0.9rem;
    padding: 0;
  }
  .item-product img {
    width: 4rem;
  }
  .img-avisos {
    padding: 0 0.8rem;
    height: 4rem;
  }
}

@media screen and (max-width: 321px) {
  .different {
    font-size: 1.5rem;
    padding: 2rem;
    padding-bottom: 0;
  }
  .item-product {
    padding-bottom: 2rem;
  }
  .item-product h5 {
    font-size: 1.2rem;
  }
  .container-list {
    width: 250px;
    margin: 1.5rem 1.5rem;
    margin-bottom: 0;
  }
  .item-list {
    font-size: 1rem;
  }
  .item-product p {
    font-size: 0.9rem;
  }
  .item-product img {
    width: 4rem;
  }
  .img-avisos {
    padding: 0 0.8rem;
    height: 4rem;
  }  
}