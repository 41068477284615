#services {
  display: flex;
  background-color: #fff204;
}
.services-bar {
  background-image: url(../../../public/images/icons/barra_blanca.png);
  background-repeat: no-repeat;
  background-size: 2.15%;
  background-position: right;
  background-position-y: center;
  width: 50%;
}
.services-text {
  padding-top: 7vw;
  padding-left: 14vw;
  padding-right: 4vw;
  display: flex;
  flex-direction: column;
}
.services-line-title {
  width: fit-content;
  height: 200px;
  margin-bottom: 3vw;
}
.services-line {
  position: relative;
  top: 0;
  left: 60px;
  width: 180px;
}
.services-title {
  position: relative;
  top: -130px;
  left: 0;
  font-size: 4rem;
  font-weight: 800;
}
.services-paragraph {
  font-weight: 350;
  font-size: 1.5vw;
  line-height: 2.5vw;
}
.img-services {
  display: block;
  width: 50%;
  height: fit-content;
}

@media screen and (min-width: 1209px) and (max-width: 1458px) {
  .services-line-title {
    margin-bottom: 2vw;
  }
  .services-text {
    padding-top: 5vw;
  }
}

@media screen and (min-width: 767px) and (max-width: 1208px) {
  .services-text {
    padding-top: 3.5vw;
    padding-left: 10vw;
    padding-right: 4vw;
  }
  .services-line-title {
    height: 160px;
    margin-bottom: 0;
  }
  .services-line {
    width: 140px;
    left: 40px;
  }
  .services-title {
    font-size: 3rem;
    top: -100px;
    left: 0;
  }
  .services-paragraph p {
    padding-right: 50px;
  }
}

@media screen and (min-width: 401px) and (max-width: 766px) {
  #services {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-services {
    width: 100%;
  }
  .services-bar {
    width: 100%;
    background-image: none;
  }
  .services-text {
    text-align: center;
    margin: 0;
    padding: 2rem 1rem;
  }
  .services-line-title {
    width: 100%;
    height: 100px;
  }
  .services-line {
    width: 100px;
    left: auto;
  }
  .services-title {
    top: -70px;
    font-size: 2rem;
  }
  .services-paragraph {
    padding: 0 1.4rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .services-paragraph p:first-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 372px) and (max-width: 400px) {
  #services {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-services {
    width: 100%;
  }
  .services-bar {
    width: 100%;
    background-image: none;
  }
  .services-text {
    text-align: center;
    margin: 0;
    padding: 2rem 1rem;
  }
  .services-line-title {
    width: 100%;
    height: 100px;
  }
  .services-line {
    width: 100px;
    left: auto;
  }
  .services-title {
    top: -70px;
    font-size: 2rem;
  }
  .services-paragraph {
    padding: 0 1.2rem;
    font-size: 1rem;
    line-height: 2rem;
  }
  .services-paragraph p:first-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 301px) and (max-width: 371px) {
  #services {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-services {
    width: 100%;
  }
  .services-bar {
    width: 100%;
    background-image: none;
  }
  .services-text {
    text-align: center;
    margin: 0;
    padding: 2rem 0.75rem;
  }
  .services-line-title {
    width: 100%;
    height: 100px;
  }
  .services-line {
    width: 100px;
    left: auto;
  }
  .services-title {
    top: -70px;
    font-size: 2rem;
  }
  .services-paragraph {
    padding: 0;
    font-size: 1rem;
    line-height: 2rem;
  }
  .services-paragraph p:first-child {
    margin-bottom: 0;
  }
}

  @media screen and (max-width: 300px) {
    #services {
      display: flex;
      flex-direction: column-reverse;
    }
    .img-services {
      width: 100%;
    }
    .services-bar {
      width: 100%;
      background-image: none;
    }
    .services-text {
      text-align: center;
      margin: 0;
      padding: 2rem 0.9rem;
    }
    .services-line-title {
      width: 100%;
      height: 100px;
    }
    .services-line {
      width: 100px;
      left: auto;
    }
    .services-title {
      top: -70px;
      font-size: 2rem;
    }
    .services-paragraph {
      padding: 0;
      font-size: 0.8rem;
      line-height: 2rem;
    }
    .services-paragraph p:first-child {
      margin-bottom: 0;
    }
  }

